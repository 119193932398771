import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import styles from '../../styles/PropositionSection.module.css';

const PropositionSection = () => {
  return (
    <section id='proposition' className={styles.propositionSection}>
      <div className={styles.story}>
        <div className={styles.storyItem}>
          <h3>Experience <span className="orange">the Difference Transparent</span> and Results-Driven Recruitment</h3>
          <p>we understand your frustrations with the numerous recruiters, headhunters, and talent acquisition consultants who often fail to deliver the value they promise. 
              that's why we stand apart from the crowd and offer a refreshing approach to recruitment.</p>
          <div className={styles.prop}>
            <h5>here's our proposition*</h5>
            <p>tell us about your challenging IT position that has been difficult to fill, and let us showcase our expertise. we are confident in our ability to find high-quality
            candidates who meet your specific requirements. share the fee you are willing to pay for successfully bringing a top-notch professional on board, and leave the job to 
            us.</p>
            <p>we are committed to delivering tangible results and providing a seamless recruitment experience.no empty promises or marketing gimmicks—just effective solutions 
                tailored to your needs.</p>
            <p>experience the difference in recruitment. <ScrollLink 
                                                                to='contact' 
                                                                smooth={true} 
                                                                duration={500}
                                                                className={styles.link}> get in touch with us
                                                        </ScrollLink> or 
                                                        <a 
                                                            href="https://calendly.com/_abaddion/30min" 
                                                            target='_blank'
                                                            rel='noopener noreferrer'>
                                                            <span> book a meeting</span> 
                                                        </a> today to discuss your hard-to-fill IT position and let us demonstrate our capabilities.
            </p>
            <p className={styles.plsNote}>* the proposition outlined above is for hiring managers and companies who have not worked with us before and is limited to one position. for 
                long-term partnerships and additional services, specific fee structures will apply, which will be discussed and agreed upon by both parties. 
                        <ScrollLink 
                            to="contact" 
                            smooth={true} 
                            duration={500} 
                            className={styles.link}> contact us 
                            </ScrollLink> to discuss your recruitment needs and explore the possibilities of a long-term partnership.
            </p>
          </div>
        </div>

        <div className={styles.storyItem}>
          <div className={styles.blackBox}>
            <h5>what sets us apart?</h5>
            <p>unlike many other recruitment agencies and freelancers, <span className="orange">we do match skills, but also people and culrure</span>. we prioritize 
                results and transparent collaboration. we won't waste your time with lengthy sales pitches that fail to deliver real outcomes. instead, we invite you 
                to experience the difference firsthand.</p>
            <div className={styles.tryImage}>
              <img 
                src="images/bulb.webp" 
                alt="a male hand dressed in suit holding a bulb"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropositionSection;