import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from '../styles/Navbar.module.css';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isHomePage = location.pathname === '/';

  return (
    <nav id="home">
      <section id={styles.navbar}>
        <div className={`${styles.navbarContainer} ${scrolled ? styles.scrolled : ''}`}>
          <div className={styles.logo}>
            <RouterLink to="/" className={styles.routerLink}>
              <LazyLoadImage 
                src='/images/logo-gold.webp'
                alt='recberry logo gold circle with letter R in the middle and text recberry agency hackers at the right side next to the golden circle'
                effect="blur"
              />
            </RouterLink>
          </div>
          <div className={styles.menu}>
            <span>
              <RouterLink to="/Jobs" className={styles.routerLink}>
                <span className={styles.jobsLink}>JOBS</span>
              </RouterLink>
            </span>
            <span>
              <RouterLink 
                to="/KnowHow" 
                className={`${styles.routerLink} ${location.pathname === '/KnowHow' ? styles.active : ''}`}
              >
                KNOW-HOW
              </RouterLink>
            </span>
            {isHomePage && (
              <>
                <span>
                  <ScrollLink to="team" className={styles.scrollLink}>TEAM</ScrollLink>
                </span>
                <span>
                  <ScrollLink to="contact" className={styles.scrollLink}>CONTACT</ScrollLink>
                </span>
              </>
            )}
          </div>
        </div>
      </section>
    </nav>
  );
};

export default Navbar;