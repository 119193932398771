import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import Landing from './Landing';
import styles from '../styles/Home.module.css';
import cityBackground from '../images/city.webp';

const Home = () => {
  return (
    <div>

      <section id='hero' className={styles.hero} style={{backgroundImage: `url(${cityBackground})`}}>
        <div className={styles.heroContent}>
          <div className={styles.heroTitle}>
            <h1>
              kickstart your IT <br/>
              hiring journey with <br/>
              us!
            </h1>
            <ScrollLink 
              to='proposition' 
              smooth={true} 
              duration={500}
            >
              <button 
                type='button' 
                name='button'
              >
                LEARN MORE
              </button>
            </ScrollLink>
          </div>
        </div>
      </section>
      
      <Landing />
    </div>
  );
};

export default Home;