import React from 'react';
import styles from '../../styles/WhitepaperSection.module.css';
import DownloadWhitepaper from '../DownloadWhitepaper';

const WhitepaperSection = ({ showDownloadWhitepaper, handleDownloadClick, handleDownloadPopupClose }) => {
    return (
        <section id='whitepaper' className={styles.whitepaper}>
            <div className={styles.whitepaperContent}>
                <h3>Download our <span className={styles.orange}>Free<br/>
                    White Paper</span>!</h3>
                <h5>Discover the Future of IT Recruitment:<br/>
                    Strategies for Success in a Digital Era</h5>
                <p>are you ready to unlock the secrets to revolutionizing your IT recruitment process? our exclusive white paper is your essential guide to conquering the evolving world 
                    of IT talent acquisition.</p>
                <p>in this comprehensive report, we delve deep into the intricacies of IT recruitment, providing valuable insights, data, and expert strategies to ensure your recruitment 
                    efforts shine in the digital age. </p>

                <h2>Our <span className={styles.orange}>White Paper</span> includes:</h2>

                <ul>
                    <li>
                        <strong>Executive Summary:</strong> a condensed overview of the key points and takeaways.
                    </li>
                    <li>
                        <strong>Current Landscape of IT Recruitment:</strong> an in-depth analysis of the ever-changing IT job market.
                    </li>
                    <li>
                        <strong>Strategies for Successful IT Recruitment:</strong> tried-and-tested methods for attracting top-tier IT talent.
                    </li>
                    <li>
                        <strong>Leveraging Technology in IT Recruitment:</strong> harnessing technology to streamline your hiring process.
                    </li>
                    <li>
                        <strong>Nurturing and Retaining IT Talent:</strong> keeping your valuable IT professionals engaged and motivated.
                    </li>
                    <li>
                        <strong>Overcoming IT Recruitment Challenges:</strong> practical solutions to common hurdles in hiring.
                    </li>
                    <li>
                        <strong>Case Studies and Success Stories:</strong> real-life examples of effective IT recruitment strategies.
                    </li>
                    <li>
                        <strong>Future Trends in IT Recruitment:</strong> stay ahead of the curve with upcoming industry developments.
                    </li>
                    <li>
                        <strong>Conclusion:</strong> a final wrap-up and actionable steps to implement in your recruitment approach.
                    </li>
                </ul>

                <p>don't miss this exclusive opportunity to gain a competitive edge in the IT recruitment landscape. simply enter your contact email address below to receive instant access 
                    to our white paper. empower your team with the knowledge and strategies they need to thrive in the digital era of recruitment.</p>
                <p>download our free white paper now and revolutionize your IT recruitment journey! </p>
                
                <div>
                    <button onClick={handleDownloadClick}>FREE WHITEPAPER</button>
                </div>
            </div>
            <div className={styles.whitepaperImage}>
                <img 
                    src='/images/document.webp'
                    alt='a document with secret content above which is a bulb giving a little light on the document dark blue background' 
                />
            </div>
            {showDownloadWhitepaper && (
                <div className={styles.modalOverlay}>
                    <DownloadWhitepaper
                        onClose={handleDownloadPopupClose}
                    />
                </div>
            )}
        </section>
    );
};

export default WhitepaperSection;