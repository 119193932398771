import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import HiringManagerSection from './KnowHowPage/HiringManagerSection';
import TalentSection from './KnowHowPage/TalentSection';
import PDFSection from './KnowHowPage/PDFSection';
import WhitepaperSection from './KnowHowPage/WhitepaperSection';
import styles from '../styles/KnowHow.module.css';
import spaceBackground from '../images/space.webp';

const KnowHow = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [dataProtectionConsent, setDataProtectionConsent] = useState(false);
    const [showDownloadWhitepaper, setShowDownloadWhitepaper] = useState(false);

    const handleDownloadPopupClose = () => {
        setShowDownloadWhitepaper(false);
    };

    const handleDownloadClick = () => {
        setShowDownloadWhitepaper(true);
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();
      
        try {
            if (!email) {
                setMessage('Email is required');
                return;
            }
        
            if (!dataProtectionConsent) {
                setMessage('Please consent to data protection');
                return;
            }
        
            const response = await fetch('/api/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
        
            if (response.ok) {
                setMessage('Thank you for subscribing!');
            } else {
                const errorMessage = data.error || 'Failed to subscribe';
                setMessage(errorMessage);
            }
        } catch (error) {
            console.error(error);
            setMessage('Failed to subscribe');
        }
    };

    return (
        <div className={styles.knowHow}>
            
            <section id='intro' className={styles.hero} style={{backgroundImage: `url(${spaceBackground})`}}>
                <div className={styles.heroContent}>
                    <div className={styles.heroTitle}>
                        <h1>the industry <br/>know-how</h1>
                        <h2 className={styles.headline}>
                            Unlock Your <span className={styles.orange}>Career</span><br/> 
                            <span className={styles.orange}>Potential</span> and tune up Your <br/>
                            <span className={styles.orange}>Hiring Process:</span> Exclusive <br/>
                            Insights and Resources for <br/>
                            <span className={styles.orange}>Candidates</span> and <span className={styles.orange}>Hiring Managers!</span>
                        </h2>
                        <ScrollLink to='pdf' smooth={true} duration={500}>
                            <button type='button' name='button'>EXPLORE</button>
                        </ScrollLink>
                    </div>
                </div>
            </section>
            
            <main className={styles.main}>
                <HiringManagerSection />
                <TalentSection />
                <PDFSection 
                    email={email}
                    setEmail={setEmail}
                    dataProtectionConsent={dataProtectionConsent}
                    setDataProtectionConsent={setDataProtectionConsent}
                    handleSubscribe={handleSubscribe}
                    message={message}
                />
                <WhitepaperSection 
                    showDownloadWhitepaper={showDownloadWhitepaper}
                    handleDownloadClick={handleDownloadClick}
                    handleDownloadPopupClose={handleDownloadPopupClose}
                />
            </main>
        </div>
    );
};

export default KnowHow;