import React from 'react';
import DownloadServices from '../DownloadServices';
import styles from '../../styles/ServicesSection.module.css';

const ServicesSection = ({ showDownloadServices, handleDownloadClick, handleDownloadPopupClose }) => {
  return (
    <section id='services' className={styles.servicesSection}>
      <div className={styles.story}>
        <div className={styles.storyItem}>
          <img 
            src='images/services02.webp' 
            alt='a male and a female having a meeting using laptop in the office'
            className={styles.maleImg}
          />
        </div>
        <div className={styles.storyItem}>
          <h3>Offered <span className="orange">services</span>: <span className="orange">Elevate</span> Your IT Talent Acquisition</h3>
          <p>In today's tech world, top talent is your advantage. We make it simple for busy CEOs. Here's what we do:</p>
          
          <ul><span className="orange">key benefits</span>
            <li><strong>Better Talent</strong>: we find top IT talent for you.</li>
            <li><strong>Boosted Results</strong>: get more done, faster, and profitably.</li>
            <li><strong>No Hiring Mistakes</strong>: we save you time and money with thorough screening.</li>
            <li><strong>Stronger Brand</strong>: attract top talent and help them grow.</li>
            <li><strong>Flexible Hiring</strong>: adapt to the changing workforce effortlessly.</li>
          </ul>

          <ul><span className="orange">our services</span>
            <li><strong>Effective Recruitment</strong>: we find the best.</li>
            <li><strong>Smooth Processes</strong>: making hiring easy.</li>
            <li><strong>Tailored Services</strong>: customized to your needs.</li>
          </ul>

          <ul><span className="orange">why choose us</span>
            <li><strong>Efficiency</strong>: fast results, no fuss.</li>
            <li><strong>Complete Solutions</strong>: from start to finish.</li>
            <li><strong>Proven Success</strong>: trusted by tech CTOs and founders.</li>
          </ul>
          <p>Download our comprehensive services PDF to discover more. We're eager to connect with you soon!</p>

          <div>
            <button onClick={handleDownloadClick}>OUR SERVICES</button>
            {showDownloadServices && (
              <DownloadServices
                onClose={handleDownloadPopupClose}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;