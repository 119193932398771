import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from '../../styles/RecommendationSection.module.css';

const RecommendationSection = () => {
  const recommendations = [
    {
      quote: "I rely on Barbora already for more than 2 years. She is always the person I go to when we need to hire new engineers into our projects. She has a great sense for recognising the talent and culture fit into our teams. I've never seen anyone structuring all the information as well as she is.",
      name: "Robert Krejca",
      position: "CEO and Culture Jockey",
      link: "https://www.linkedin.com/in/krejca/",
      animClass: styles.anim1
    },
    {
      quote: "I've met dozens of recruiters who claimed to be \"determined to help us\", but until I met Barbora, I didn't think they actually presented a real added value. Building employment branding, changing the way we approach our future employees, all that helped me to understand and appreciate the way Barbora works. We got our first guy with a snap of a finger, and the whole process was smooth and flawless.",
      name: "Jan Klusacek",
      position: "CEO @ Cyber Fox",
      link: "https://www.linkedin.com/in/jan-klusacek-b162746/",
      animClass: styles.anim2
    },
    {
      quote: "Barbora was helping us on consulting basis and helped us hire 4 great engineers just in 2 months. I'm very happy with the result of our cooperation and definitely recommend Barbora to anyone who struggles to acquire talent on today's tough tech job market.",
      name: "Marian Skvarek",
      position: "Founder @ Realpad",
      link: "https://www.linkedin.com/in/mskvarek/",
      animClass: styles.anim3
    },
    {
      quote: "I used to cooperate with Barbora in REED. I do percept our cooperation as very successful one. Barbora is one of the very few agency consultants who has managed to get any Hires @Skype = must be good! Her friendly approach combined technical background and passion in technology I can recommend as interesting benefit for anyone who is looking for skilled IT professionals.",
      name: "Miroslav Vasko",
      position: "Partner @ nymble ltd, former @ Skype",
      link: "https://www.linkedin.com/in/miroslavvasko/",
      animClass: styles.anim4
    },
    {
      quote: "Regardless of whether we needed an in-house employee, a contractor, a developer or a tester, Barbora consistently met the challenge. From tight budget to searching in less conventional European regions, or seeking quite peculiar skill set or experience, Barbora always found a way. Beyond her recruiting talents, she was able to provide invaluable HR consultancy. She understands the essence of organisation dynamics and fits. To anyone seeking a seasoned recruiter and HR adviser in Europe, I can't recommend Barbora enough. Our collaboration has been both productive and enjoyable.",
      name: "Tadeáš Moravec",
      position: "Senior Engineering Manager, former @ Akamai",
      link: "https://www.linkedin.com/in/tadeasmoravec/",
      animClass: styles.anim5
    }
  ];

  return (
    <section id='recommendation' className={styles.recommendationSection}>
      <div className={styles.story}>
        <div className={styles.storyItem}>
          <LazyLoadImage 
            src={`${process.env.PUBLIC_URL}/images/male.webp`}
            alt='a male in suit looking professional with dark blue background and gold details behind him'
            className={styles.maleImg}
            effect="blur"
          />
        </div>
        <div className={styles.storyItem}>
          <h3>Unlocking <span className={styles.orange}>Success</span> Stories Client <span className={styles.orange}>Recommendations</span></h3>
          <div className={styles.recommend}>
            <div className={styles.contentSlider}>
              <div className={styles.slider}>
                <div className={styles.mask}>
                  <ul>
                    {recommendations.map((rec, index) => (
                      <li key={index} className={rec.animClass}>
                        <div className={styles.quote}>
                          <q>{rec.quote}</q>
                        </div>
                        <div className={styles.source}>
                          <p>
                            <a href={rec.link} target="_blank" rel="noopener noreferrer">
                              {rec.name}
                            </a><br/>
                            {rec.position}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecommendationSection;