import React from 'react';
import styles from '../../styles/AboutSection.module.css';

const AboutSection = () => {
  return (
    <section id='more-about' className={styles.aboutSection}>
      <div className={styles.story}>
        <div className={styles.storyItem}>
        <h3>
        <span className="orange">Redefining</span> Recruitment with an{' '}
        <span className="orange">innovative</span> and new Agency{' '}
        <span className="orange">Approach</span>
      </h3>
          <p>recberry emerged from a rich portfolio of esteemed clients in the czech market and insights gained from working with multiple personal agencies. we 
              recognised the need for change and identified the shortcomings in traditional agency practices driven solely by profit. </p>
          <p>our mission is to provide genuine value to both clients and candidates by redefining the role of a recruiter. we are committed to building long-lasting, 
              successful teams and reshaping the recruitment landscape.</p>
          <p>recberry brings a fresh perspective and we are driven by our extensive IT recruitment know-how and a vision to revolutionise how agencies operate. by
              prioritising innovation and delivering a superior recruitment experience, we aim to set new standards in the industry. </p>
          <button 
            type='button' 
            name='button'
            onClick={() => window.open('https://calendly.com/_abaddion/30min', '_blank', 'noopener noreferrer')}>
            BOOK A MEETING
          </button>
        </div>

        <div className={styles.storyItem}>
          <img 
            src='images/ai.webp' 
            alt='ai face blue background net conections data scientific style' 
            className={styles.storyImg}
          />
        </div>
      </div>
    </section>
  );
};

export default AboutSection;