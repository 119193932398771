import { API_BASE_URL } from '../config';

export const submitReferral = async (data) => {
  try {
    const response = await fetch(`${API_BASE_URL}/referral`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const downloadPdf = async (pdfName) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/download-pdf/${pdfName}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${pdfName}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    throw error;
  }
};

export const collectEmail = async (email) => {
  try {
    const response = await fetch(`${API_BASE_URL}/collect-client-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
};

export const downloadWhitepaper = async (email) => {
  try {
    const response = await fetch(`${API_BASE_URL}/download-whitepaper`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    return response.json();
  } catch (error) {
    throw error;
  }
};