import React from 'react';
import styles from '../../styles/PortfolioSection.module.css';

const PortfolioSection = () => {
  return (
    <section id='portfolio' className={styles.portfolioSection}>
      <div className={styles.story}>
        <div className={styles.storyItem}>
        <h3><span className="orange">Trusted</span> Partners Our Impressive <span className="orange">Clientele</span></h3>

          <span className={styles.logoClient}>
            <img src='images/be.webp' alt='brand embassy'/>
          </span>
          <span className={styles.logoClient}>
            <img src='images/angel.webp' alt='angelcam' />
          </span>
          <span className={styles.logoClient}>
            <img src='images/akamai.webp' alt='akamai'/>
          </span>
          <span className={styles.logoClient}>
            <img src='images/suse.webp' alt='suse linux'/>
          </span>
          <span className={styles.logoClient}>
            <img src='images/o.webp' alt='OPT On Demand'/>
          </span>
          <span className={styles.logoClient}>
            <img src='images/ec.webp' alt='easycore'/>
          </span>
          <span className={styles.logoClient}>
            <img src='images/v.webp' alt='vendavo'/>
          </span>
          <span className={styles.logoClient}>
            <img src='images/skype.webp' alt='skype'/>
          </span>          
        </div>
        <div className={styles.storyItem}>
          <img 
            src='images/female.webp'
            alt='female recruiter blue background blond professional in suit with gold aspects in the background'
            className={styles.femaleImg}
          />
        </div>               
      </div>
    </section>
  );
};

export default PortfolioSection;