import React from 'react';
import styles from '../../styles/TalentHMSection.module.css';
import FormHM from '../FormHM';

const HiringManagerSection = () => {
    return (
        <section id='hiring-manager' className={styles.talent}>
            <div className={styles.subSection}>
                <div className={styles.subItem}>
                    <h2>the hiring managers & hr</h2>
                    <div className={styles.subSection}>
                        <div className={styles.transparentBorder}>
                            <div className={styles.subSectionHalf}>
                                <div className={styles.subItems}>
                                    <h4>Streamline Recruitment Process</h4>
                                    <p className={styles.styled}>our resources provide you with proven strategies and best practices to streamline your recruitment process. from crafting compelling 
                                        job descriptions and conducting effective interviews to implementing robust candidate assessment methods, we help you save time and effort while making 
                                        informed hiring decisions.</p>
                                </div>
                                <div className={styles.subItems}>
                                    <h4>Access Top Talent</h4>
                                    <p className={styles.styled}>gain a competitive edge in attracting and securing top talent. our insights help you understand the candidate market dynamics, enabling you to 
                                        create attractive employer value propositions, build a strong employer brand, and effectively engage with high-quality candidates.</p>
                                </div>
                            </div>
                            <div className={styles.subSectionHalf}>
                                <div className={styles.subItems}>
                                    <h4>Improve candidate selection</h4>
                                    <p className={styles.styled}>make confident and data-driven decisions when evaluating candidates. our advice equips you with advanced assessment techniques, interview guidance, 
                                        and behavioral analysis frameworks to identify candidates who not only possess the required skills but also fit seamlessly within your company culture.</p>
                                </div>
                                <div className={styles.subItems}>
                                    <h4>Stay informed and adaptable</h4>
                                    <p className={styles.styled}>our resources keep you updated on the latest recruitment trends, technology advancements, and industry insights. stay ahead of the curve and adapt your 
                                    hiring strategies to changing market dynamics, ensuring your company remains competitive in attracting and retaining top talent.</p>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
                <div className={styles.subItem}>
                    <h5>Don't miss out on these exclusive benefits! Enter your email address below to access our 
                        downloadable PDFs and unlock the hiring excellence your organization deserves.</h5>
                    <FormHM />
                </div>
            </div>
        </section>
    );
};

export default HiringManagerSection;