import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from '../styles/Footer.module.css';

const Footer = () => {
  return (
    <footer id='footer' className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.socialProfiles}>
          <a
            href='https://cz.linkedin.com/company/recberry'
            target='_blank'
            rel='noopener noreferrer'
            className={styles.socialLink}
          >
            LinkedIn
          </a>
          <a
            href='https://cs-cz.facebook.com/recberry/'
            target='_blank'
            rel='noopener noreferrer'
            className={styles.socialLink}
          >
            Facebook
          </a>
          {/* <a
            href='https://www.foxcrunch.com/'
            target='_blank'
            rel='noopener noreferrer'
            className={styles.socialLink}
          >
            Blog
          </a> */}
          <a
            href='https://twitter.com/rec_berry'
            target='_blank'
            rel='noopener noreferrer'
            className={styles.socialLink}
          >
            X / twitter
          </a>
        </div>
        <div className={`${styles.line} ${styles.lineLeft}`}></div>
        <div className={`${styles.line} ${styles.lineRight}`}></div>
      </div>

      <div className={styles.rights}>
        <p>
          &copy; all rights reserved. designed and developed by{' '}
          <LazyLoadImage
            src={`${process.env.PUBLIC_URL}/images/atom.webp`}
            alt="Logo"
            effect="blur"
            height={20}
            width={20}
          />{' '}
          <a href='http://abaddion.com' className={styles.link}>
            abaddion
          </a>
        </p>
      </div>

      <div className={styles.business}>
        <p>
          Recberry s.r.o.| IC: 04972236 | DIC: CZ04972236 | Tupolevova 741,
          Prague 9 Letnany | Incorporated in the Commercial Register kept by
          the City: Court in Prague, Section C, under file No.256441
        </p>
      </div>
    </footer>
  );
};

export default Footer;