import React, { useState } from 'react';
import ReCaptcha from './ReCaptcha';
import DataProtection from './DataProtection';
import styles from '../styles/Forms.module.css';

const FormTalent = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        job: '',
        company: '',
        profile: '',
        dataProtectionConsent: false,
        isContactPolicyChecked: true
    });
    const [recaptchaCompleted, setRecaptchaCompleted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleRecaptchaChange = () => {
        setRecaptchaCompleted(true);
    };

    const handleTalentSubscription = async (e) => {
        e.preventDefault();
    
        if (!formData.email || !formData.dataProtectionConsent) {
            setResponseMessage('Please fill all required fields');
            return;
        }
    
        if (!recaptchaCompleted) {
            setResponseMessage('Please complete the reCAPTCHA');
            return;
        }

        try {
            const response = await fetch('https://formspree.io/f/mrbgbglg', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
    
            if (response.ok) {
                setResponseMessage('Thank you for your subscription.');
                setFormData({
                    name: '',
                    email: '',
                    job: '',
                    company: '',
                    profile: '',
                    dataProtectionConsent: false,
                    isContactPolicyChecked: true
                });
                setRecaptchaCompleted(false);
                if (window.grecaptcha) {
                    window.grecaptcha.reset();
                }
            } else {
                throw new Error('Failed to submit form');
            }
        } catch (error) {
            console.error(error);
            setResponseMessage('Failed to subscribe. Please try again.');
        }
    };

    return (
        <div className={styles.formContainer}>
            <div className={styles.rightForm}>
                <form id='contact-form' onSubmit={handleTalentSubscription}>
                    <label htmlFor='name'>Your full name</label>
                    <input 
                        type='text'
                        id='name' 
                        name='name'
                        autoComplete='name'
                        required
                        value={formData.name}
                        onChange={handleChange}
                    />
    
                    <label htmlFor='email'>Your contact email address</label>
                    <input 
                        type='email' 
                        id='email'
                        name='email' 
                        autoComplete='email'
                        required
                        value={formData.email}
                        onChange={handleChange}
                    />
                    
                    <label htmlFor='job'>Your job title</label>
                    <input 
                        type='text' 
                        id='job'
                        name='job' 
                        autoComplete='organization-title'
                        required
                        value={formData.job}
                        onChange={handleChange}
                    />
                    
                    <label htmlFor='company'>The company</label>
                    <input 
                        type='text' 
                        id='company'
                        name='company' 
                        autoComplete='organization'
                        required
                        value={formData.company}
                        onChange={handleChange}
                    />

                    <label htmlFor='profile'>Your LinkedIn profile</label>
                    <input 
                        type='text' 
                        id='profile'
                        name='profile' 
                        autoComplete='url'
                        required
                        value={formData.profile}
                        onChange={handleChange}
                    />
                    
                    <div className={styles.contactPolicy}>
                        <input 
                            type='checkbox' 
                            id='contact-policy' 
                            name='isContactPolicyChecked' 
                            checked={formData.isContactPolicyChecked}
                            onChange={handleChange}
                        />
                        <label htmlFor='contact-policy'>
                            Yes, I want to be contacted by recberry <strong>to help me with my career</strong>.
                        </label>
                    </div>
                    
                    <div className={styles.dataPolicy}>
                        <input 
                            type='checkbox' 
                            id='data-policy' 
                            name='dataProtectionConsent' 
                            required
                            checked={formData.dataProtectionConsent}
                            onChange={handleChange}
                        />
                        <DataProtection />
                    </div>

                    <ReCaptcha onChange={handleRecaptchaChange} />

                    <button type='submit'>SUBSCRIBE</button>
                </form>
                {responseMessage && <p className={styles.message}>{responseMessage}</p>}
            </div>
        </div>
    );
};
export default FormTalent;