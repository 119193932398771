import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import KnowHow from './components/KnowHow';
import Jobs from './components/Jobs';
import Data from './components/Data'; 
import Footer from './components/Footer';
import './styles/global.css';

function App() {
  useEffect(() => {
    // Load reCAPTCHA script
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/KnowHow" element={<KnowHow />} />
          <Route path="/Jobs" element={<Jobs />} />
          <Route path="/Data" element={<Data />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;