import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from '../../styles/TeamSection.module.css';

const TeamSection = () => {
  const teamMembers = [
    {
      name: 'Barbora JENSIK',
      role: 'founder & director',
      image: 'images/bb.webp',
      alt: 'a female face of barbora jensik, before balazikova, if not visible, check her linkedin profile https://www.linkedin.com/in/barushbalazikova/',
      description: 'Passionate UX/UI Designer, Web Developer, Principal IT Recruiter',
      bio: 'passionate about fine art and designing captivating UX/UI interfaces, I excel in web development using HTML, CSS, ReactJS, Redux, NodeJS, and ExpressJS. with a strong background in recruitment and employment branding, I expertly match IT developers with their ideal career opportunities.',
      portfolio: 'http://abaddion.com/',
      social: [
        { icon: 'fab fa-linkedin-in', link: 'https://www.linkedin.com/in/barushbalazikova/' },
        { icon: 'fas fa-blog', link: 'https://www.foxcrunch.com/' },
        { icon: 'fab fa-instagram', link: 'https://www.instagram.com/abaddion/' },
        { icon: 'fab fa-behance', link: 'https://www.behance.net/abaddion' },
        { icon: 'fab fa-github', link: 'https://github.com/abaddion' }
      ]
    },
    {
      name: 'Maria PRAZSKA',
      role: 'networker & data miner',
      image: 'images/ms.webp',
      alt: 'a female face of maria prazska, before simova. if not visible check her linkedin profile https://www.linkedin.com/in/mariaprazska/',
      description: 'Innovative Talent Acquisition Specialist & Recruitment Advocate',
      bio: 'passionate about modernising recruitment, I aim to transform traditional enterprises into thriving online startups. treating candidates as individuals and prioritising quality, I connect top talents with exceptional teams in a professional and friendly atmosphere. with a mission to revolutionise czech labor market\'s recruitment process, I strive to restore the value of third-party services.',
      social: [
        { icon: 'fab fa-linkedin-in', link: 'https://www.linkedin.com/in/mariaprazska/' },
        { icon: 'fab fa-facebook-f', link: 'https://www.facebook.com/maria.simova.56' },
        { icon: 'fab fa-instagram', link: 'https://www.instagram.com/mariprazska/' }
      ]
    },
    {
      name: 'Alexandra KUMICIKOVA',
      role: 'networker & data miner',
      image: 'images/ak.webp',
      alt: 'a female face of alexandra kumicikova. if not visible check her linkedin profile at https://www.linkedin.com/in/alexandra-kumič%C3%ADková-8b492383/',
      description: 'Talent Acquisition Specialist & HR Coordinator',
      bio: 'with a proven track record in HR, recruitment, and marketing, I specialise in creating and managing talent pools, primarily for software developers. from raising awareness about our company to conducting initial candidate screenings, resourcing, and interviews, I handle the end-to-end recruitment process. additionally, I excel in event management, organising hackathons and team-building activities.',
      social: [
        { icon: 'fab fa-linkedin-in', link: 'https://www.linkedin.com/in/alexandra-kumič%C3%ADková-8b492383/' },
        { icon: 'fab fa-facebook-f', link: 'https://www.facebook.com/alexandra.kumicikova' },
        { icon: 'fab fa-instagram', link: 'https://www.instagram.com/sasenocena/' }
      ]
    },
    {
      name: 'Viktoria SZTEZKALOVA',
      role: 'networker & data miner',
      image: 'images/vs.webp',
      alt: 'a female face of viktoria sztezkalova. if not visible check her LinkedIn profile at https://www.linkedin.com/in/alexandra-kumič%C3%ADková-8b492383/',
      description: 'Passionate HR Management Student and IT Recruiter',
      bio: 'final year HR student and dedicated IT recruiter with a knack for connecting top talent. despite being an introvert, I excel in understanding goals and finding fulfilling career opportunities. committed to the best fit for candidates, I prioritise their happiness over all else. let\'s connect for exciting growth opportunities.',
      social: [
        { icon: 'fab fa-linkedin-in', link: 'https://www.linkedin.com/in/viktoria-sztezkalova-15350b250/' },
        { icon: 'fab fa-facebook-f', link: 'https://www.facebook.com/viki.sztezkalova' }
      ]
    }
  ];

  return (
    <>
      <div className={styles.teamImg}>
        <LazyLoadImage 
          src={`${process.env.PUBLIC_URL}/images/team-org.webp`}
          alt='six members of a professional team in suits blue background office lighting'
          effect="blur"
        /> 
      </div> 
      
      <section id='team' className={styles.teamSection}> 
        <div className={styles.radiantBckg}></div>
        
        <div className={styles.theTeam}>
          {teamMembers.map((member, index) => (
            <div key={index} className={styles.memberCard}>
              <div className={styles.memberCardTop}>
                <LazyLoadImage 
                  src={`${process.env.PUBLIC_URL}/${member.image}`}
                  width={160}
                  height={210} 
                  className={styles.memberImg}
                  alt={member.alt}
                  effect="blur"
                />
                <h4>{member.name}</h4>
                <p>{member.role}</p>
                <div className={styles.social}>
                  {member.social.map((social, socialIndex) => (
                    <a 
                      key={socialIndex}
                      href={social.link}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={styles.icon}
                    >
                      <i className={social.icon}></i>
                    </a>
                  ))}
                </div>            
              </div>
              <div className={styles.memberCardBottom}>
                <h5>{member.description}</h5>
                <p>{member.bio} 
                  {member.portfolio && (
                    <>
                      {' '}check out my portfolio at <a 
                        href={member.portfolio}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <span>www.abaddion.com</span>
                      </a> and GitHub profile for my programming skills.
                    </>
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default TeamSection;