import React, { useState } from 'react';
import Navbar from './Navbar';
import ReCaptcha from './ReCaptcha';
import DataProtection from './DataProtection';
import styles from '../styles/Jobs.module.css';
import globeBackground from '../images/globe_blue.webp';

const Jobs = () => {
    const [formData, setFormData] = useState({
        email: '',
        dataProtectionConsent: false
    });
    const [recaptchaCompleted, setRecaptchaCompleted] = useState(false);
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleRecaptchaChange = () => {
        setRecaptchaCompleted(true);
    };

    const handleWaiting = async (e) => {
        e.preventDefault();
      
        if (!formData.email || !formData.dataProtectionConsent) {
            setMessage('Please fill all required fields');
            return;
        }
      
        if (!recaptchaCompleted) {
            setMessage('Please complete the reCAPTCHA');
            return;
        }

        try {
            const response = await fetch('https://formspree.io/f/xjkvkvoy', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setMessage('Thank you for subscribing to our waiting list!');
                setFormData({ email: '', dataProtectionConsent: false });
                setRecaptchaCompleted(false);
                if (window.grecaptcha) {
                    window.grecaptcha.reset();
                }
            } else {
                throw new Error('Failed to submit form');
            }
        } catch (error) {
            console.error(error);
            setMessage('Failed to subscribe to our waiting list. Please try again.');
        }
    };

    return (
        <div className={styles.jobsPage}>
            <Navbar />
            <section id='jobs' className={styles.hero} style={{backgroundImage: `url(${globeBackground})`}}>
                <div className={styles.heroContent}>
                    <div className={styles.heroTitle}>
                        <h1>welcome to <br/>our job-portal</h1>
                        <h2 className={styles.headline}>
                            are you a talented <span className="orange">IT</span><br/> 
                            <span className="orange">Engineer</span> looking for exciting <br/>
                            career opportunities? You've <br/>
                            come to the right place.
                        </h2>
                    </div>
                </div>
            </section>

            <main className={styles.main}>
            <h3>Dear <span className="orange">visitor!</span><br/>
            Thank you for your interest in our career page</h3>

                <div className={styles.waitingList}>
                    <div className={styles.pdfSubscribe}>
                        <h3 className={styles.subscribeH3}><span className={styles.orange}>More Coming Soon:</span><br/></h3>
                        <p className={styles.styled}>we are currently working hard to improve and update our site. during this time, the page is under construction.<br/>
                            if you would like to be informed when it's ready, please provide your email address below, and we'll notify you once it's up and running. 
                            your patience and understanding are highly appreciated.</p>
                        <form onSubmit={handleWaiting}>
                            <label htmlFor='email'>
                                Your contact email address
                            </label>
                            <input 
                                type='email' 
                                id='email' 
                                name='email'
                                className={styles.waiting}
                                autoComplete='email'
                                required
                                value={formData.email}
                                onChange={handleChange}
                            />
                            <div className={styles.dataPolicySubs}>
                                <input 
                                    type='checkbox'
                                    id='data-policy'
                                    name='dataProtectionConsent'
                                    required
                                    checked={formData.dataProtectionConsent}
                                    onChange={handleChange}
                                />
                                <DataProtection />
                            </div>
                            <ReCaptcha onChange={handleRecaptchaChange} />
                            <button type='submit'>WAITING LIST</button>
                        </form>
                        <p className={styles.message}>{message}</p>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Jobs;