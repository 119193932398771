import React from 'react';
import FormContact from '../FormContact';
import styles from '../../styles/ContactSection.module.css';

const ContactSection = () => {
  return (
    <section id='contact' className={styles.contactSection}>
      <div className={styles.story}>
        <div className={styles.storyItem}>
          <div className={styles.blackBox}>
          <h3>Our Commitment to <span className="orange">Exceptional</span> Results</h3>
            <p>we are passionate about delivering tailored recruitment solutions that drive success for 
                our clients. with our deep industry knowledge, extensive network, and meticulous screening 
                process, we consistently connect organisations with top-tier talent who not only meet the 
                required qualifications but also align with their unique company culture. </p>
            <p>by understanding our clients' needs inside out, we ensure a seamless recruitment experience and 
                empower companies to build high-performing teams that propel their growth and competitiveness in 
                today's dynamic market. </p>
            <p>trust us to be your strategic recruitment partner, and experience the difference of working with 
                professionals who truly understand your business.</p>
          </div>
        </div>
        <div className={`${styles.storyItem} ${styles.contactForm}`}>
          <h2>Get in touch</h2>
          <FormContact />
          <div className={styles.phoneInfo}>
            <i className='fa fa-phone'></i>
            <span className={styles.mobile}>+420 702 080 993</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;