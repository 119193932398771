import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styles from '../styles/DataProtection.module.css';

const DataProtection = () => {
  return (
    <label htmlFor='data-policy' className={styles.label}>
      I hereby acknowledge and consent to comply with the{' '}
      <RouterLink to='/Data' className={styles.link}>
        <span className={styles.orange}>data protection policy</span>
      </RouterLink>
      .
    </label>
  );
};

export default DataProtection;