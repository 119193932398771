import React, { useState } from 'react';
import ReCaptcha from './ReCaptcha';
import DataProtection from './DataProtection';
import styles from '../styles/FormContact.module.css';

const FormContact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    dataProtectionConsent: false,
  });
  const [recaptchaCompleted, setRecaptchaCompleted] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleRecaptchaChange = () => {
    setRecaptchaCompleted(true);
  };

  const handleContact = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.dataProtectionConsent) {
      setResponseMessage('Please fill all required fields');
      return;
    }

    if (!recaptchaCompleted) {
      setResponseMessage('Please complete the reCAPTCHA');
      return;
    }

    try {
      const response = await fetch('https://formspree.io/f/mpwzwzre', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setResponseMessage('Thank you for your message. We will get in touch soon.');
        setFormData({
          name: '',
          email: '',
          message: '',
          dataProtectionConsent: false,
        });
        setRecaptchaCompleted(false);
        if (window.grecaptcha) {
          window.grecaptcha.reset();
        }
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error(error);
      setResponseMessage('Failed to submit message. Please try again.');
    }
  };


  return (
    <div className={styles.formContainer}>
      <form id='contact-form' onSubmit={handleContact} className={styles.form}>
        <label htmlFor='name'>Your full name</label>
        <input 
          type='text'
          id='name' 
          name='name'
          value={formData.name}
          autoComplete='name'
          required
          onChange={handleChange}
        />

        <label htmlFor='email'>Your contact email address</label>
        <input 
          type='email' 
          id='email'
          name='email' 
          value={formData.email}
          autoComplete='email'
          required
          onChange={handleChange}
        />

        <label htmlFor='message'>Your message</label>
        <textarea 
          id='message' 
          name='message' 
          value={formData.message}
          rows='4' 
          onChange={handleChange}
        ></textarea>

        <div className={styles.dataPolicy}>
          <input 
            type='checkbox' 
            id='data-policy' 
            name='dataProtectionConsent' 
            checked={formData.dataProtectionConsent}
            required
            onChange={handleChange}
          />
          <DataProtection />
        </div>

        <ReCaptcha onChange={handleRecaptchaChange} />
        
        <button type='submit'>CONTACT</button>
      </form>
      {responseMessage && <p className={styles.message}>{responseMessage}</p>}
    </div>
  );
};

export default FormContact;