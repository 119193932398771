import React from 'react';
import FormRefer from '../FormRefer';
import styles from '../../styles/IntroSection.module.css';

const IntroSection = () => {
  return (
    <section id='recberry-intro' className={styles.introSection}>
      <div className={styles.container}>
        <div className={styles.itemForm}>
          <h5>Connecting Professionals, Portfolio Sharing and Rewarding Referrals</h5>
          <p>we take pleasure in engaging with professionals, showcasing portfolio, and offering rewards for successful referrals. 
          if you recommend <span></span> who is successfully hired by one of our clients, we ensure your efforts are recognised and rewarded. </p>       
          <FormRefer />
        </div>

        <div className={styles.items}>
          <div className={styles.itemCard}>
            <h4>personal agency hackers?</h4>
            <h6>Empowering the IT Community: Revolutionising Talent Acquisition for Success</h6>
            <p className={styles.pWhite}>by leveraging our extensive headhunting experience, we have reshaped traditional personal agency practices. through
                                          a client-centric approach and innovative strategies, we deliver exceptional results. experience a new era of IT hiring, 
                                          where our agency sets new standards for value and success.</p>
          </div>
          
          <div className={styles.itemCard}>
            <h4>headhunting | data mining | networking</h4>
            <h6>Transforming the IT Hiring Landscape: Reinventing Personal Agency Practices for Unparalleled Client Value</h6>
            <p className={styles.pWhite}>leveraging extensive networks, market insights, and online platforms, we actively search for data and connect with talented 
                                          individuals. but our approach extends beyond sourcing; we cultivate long-term relationships and continuously expand our quality 
                                          candidate pool. committed to the IT community, our vision drives us to bring meaningful value and innovation.</p>
          </div>
          
          <div className={styles.itemCard}>
            <h4>additional value offering</h4>
            <h6>The Personalised Approach: Empowering Clients, Candidates, and the IT Community</h6>
            <p className={styles.pWhite}>at our agency, we prioritise the unique needs of both clients and candidates, recognising that individual attention is key. by 
                                          investing extra time to understand client challenges and develop robust recruitment strategies, we foster successful matches. 
                                          we also value getting to know the people behind the brand, ensuring alignment with candidate aspirations and supporting the 
                                          growth of the IT community.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;