import React, { useState } from 'react';
import IntroSection from './LandingSections/IntroSection';
import AboutSection from './LandingSections/AboutSection';
import ServicesSection from './LandingSections/ServicesSection';
import TeamSection from './LandingSections/TeamSection';
import PropositionSection from './LandingSections/PropositionSection';
import RecommendationSection from './LandingSections/RecommendationSection';
import PortfolioSection from './LandingSections/PortfolioSection';
import ContactSection from './LandingSections/ContactSection';
import styles from '../styles/Landing.module.css';

const Landing = () => {
  const [showDownloadServices, setShowDownloadServices] = useState(false);

  const handleDownloadPopupClose = () => {
    setShowDownloadServices(false);
  };

  const handleDownloadClick = () => {
    setShowDownloadServices(true);
  };

  return (
    <main id='main' className={styles.main}>
      <IntroSection />
      <AboutSection />
      <ServicesSection 
        showDownloadServices={showDownloadServices}
        handleDownloadClick={handleDownloadClick}
        handleDownloadPopupClose={handleDownloadPopupClose}
      />
      <TeamSection />
      <PropositionSection />
      <RecommendationSection />
      <PortfolioSection />
      <ContactSection />
    </main>
  );
};

export default Landing;