import React, { useState } from 'react';
import ReCaptcha from './ReCaptcha';
import DataProtection from './DataProtection';
import styles from '../styles/FormRefer.module.css';

const FormRefer = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    profile: '',
    message: '',
    dataProtectionConsent: false,
  });
  const [recaptchaCompleted, setRecaptchaCompleted] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleRecaptchaChange = () => {
    setRecaptchaCompleted(true);
  };

  const handleRefer = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.dataProtectionConsent) {
      setResponseMessage('Please fill all required fields');
      return;
    }

    if (!recaptchaCompleted) {
      setResponseMessage('Please complete the reCAPTCHA');
      return;
    }

    try {
      const response = await fetch(
        "https://formspree.io/f/xovqvqwr", 
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setResponseMessage("Thank you for the referral.");
        setFormData({
          name: '',
          email: '',
          profile: '',
          message: '',
          dataProtectionConsent: false,
        });
        setRecaptchaCompleted(false);
        if (window.grecaptcha) {
          window.grecaptcha.reset();
        }
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error('Submission error:', error);
      setResponseMessage('Failed to submit referral. Please try again.');
    }
  };

  return (
    <div className={styles.formContainer}>
      <form id='refer-form' onSubmit={handleRefer} className={styles.form}>
        <label htmlFor='name'>Your full name</label>
        <input 
          type='text'
          id='name' 
          name='name'
          value={formData.name}
          autoComplete='name'
          onChange={handleChange}
          required
        />

        <label htmlFor='email'>Your contact email address</label>
        <input 
          type='email' 
          id='email'
          name='email' 
          value={formData.email}
          autoComplete='email'
          onChange={handleChange}
          required
        />

        <label htmlFor='profile'>Link to profile of your referral</label>
        <input 
          type='text' 
          id='profile' 
          name='profile' 
          value={formData.profile}
          onChange={handleChange}
        />

        <label htmlFor='message'>Your message</label>
        <textarea 
          id='message' 
          name='message' 
          rows='4' 
          value={formData.message}
          onChange={handleChange}
        ></textarea>

        <div className={styles.dataPolicy}>
          <input 
            type='checkbox' 
            id='data-policy' 
            name='dataProtectionConsent' 
            checked={formData.dataProtectionConsent}
            onChange={handleChange}
            required
          />
          <DataProtection />
        </div>

        <ReCaptcha onChange={handleRecaptchaChange} />

        <button type='submit'>REFER</button>
      </form>
      {responseMessage && <p className={styles.message}>{responseMessage}</p>}
    </div>
  );
};

export default FormRefer;