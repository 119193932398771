import React, { useState } from 'react';
import ReCaptcha from '../ReCaptcha';
import DataProtection from '../DataProtection';
import styles from '../../styles/PDFSection.module.css';

const PDFSection = () => {
    const [formData, setFormData] = useState({
        email: '',
        dataProtectionConsent: false
    });
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    };

    const handleSubscribe = async (e) => {
        e.preventDefault();

        if (!formData.email || !formData.dataProtectionConsent) {
            setMessage('Please fill all required fields');
            return;
        }

        if (!recaptchaValue) {
            setMessage('Please complete the reCAPTCHA');
            return;
        }

        try {
            const response = await fetch('https://formspree.io/f/xzzbzbvk', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    'g-recaptcha-response': recaptchaValue
                }),
            });

            if (response.ok) {
                setMessage('Thank you for subscribing!');
                setFormData({ email: '', dataProtectionConsent: false });
                setRecaptchaValue(null);
                window.grecaptcha.reset();
            } else {
                throw new Error('Failed to submit form');
            }
        } catch (error) {
            console.error(error);
            setMessage('Failed to subscribe. Please try again.');
        }
    };  
     
    return (
        <section id='pdf' className={styles.pdf}>
            <h3 className={styles.mainTitle}>Access our <span className={styles.orange}>exclusive</span><br/>Downloadable PDFs</h3>
            <div className={styles.pdfContent}>
                <div className={styles.pdfHalf}>
                    <h4>the hiring managers / hr</h4>
                    <div className={styles.pdfColumns}>
                        <div className={styles.pdfColumn}>
                            <div className={styles.pdfItem}>
                                <h5>Create Stunning Job Description Checklist</h5>
                                <p className={styles.styled}>discover the essential elements and best practices to craft compelling job descriptions that attract 
                                    top talent and effectively communicate your hiring needs.</p>
                            </div>
                            <div className={styles.pdfItem}>
                                <h5>Recruitment Trends Whitepaper</h5>
                                <p className={styles.styled}>gain valuable industry insights into the evolving recruitment landscape, specifically focusing on 
                                    software engineering positions. explore emerging trends and predictions to stay ahead in the competitive talent market.</p>
                            </div>
                        </div>
                        <div className={styles.pdfColumn}>
                            <div className={styles.pdfItem}>
                                <h5>Hiring Remote Workers Checklist</h5>
                                <p className={styles.styled}>navigate the unique challenges and considerations of hiring remote workers successfully. our checklist 
                                    provides valuable insights to help you build a remote team that excels.</p>
                            </div>
                            <div className={styles.pdfItem}>
                                <h5>Tech Questions for Software Engineers</h5>
                                <p className={styles.styled}>dive into a comprehensive collection of technical questions designed to test and evaluate software 
                                    engineering proficiency.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.pdfPicture}>
                        <img 
                            src='images/hero.webp'
                            alt='a male hero cartoon in blue business suit being happy based on success or winning with glasses on his face' />
                    </div>
                </div>

                <div className={styles.pdfHalf}>
                    <h4>the talent</h4>
                    <div className={styles.pdfColumns}>
                        <div className={styles.pdfColumn}>
                            <div className={styles.pdfItem}>
                                <h5>Resume Preparation and Interview Guide</h5>
                                <p className={styles.styled}>how to prepare for interviews: equip yourself with the essential strategies, tips, and techniques 
                                    to confidently prepare for interviews. master the art of showcasing your skills and experiences effectively to 
                                    impress potential employers.</p>
                            </div>
                        </div>
                        <div className={styles.pdfColumn}>
                            <div className={styles.pdfItem}>
                                <h5>Tech Questions for Software Engineers</h5>
                                <p className={styles.styled}>find value in this resource. dive into a comprehensive collection of technical questions designed 
                                    to test and evaluate software engineering proficiency.</p>
                            </div>
                        </div>
                    </div>      
                    <div className={styles.pdfSubscribe}>
                        <h3 className={styles.subscribeH3}>
                            <span className={styles.orange}>More Coming Soon:</span><br/>
                            for Priority Access!
                        </h3>
                        <p className={styles.styled}>we're thrilled to announce that our dedicated team is actively working on creating more valuable PDF resources for both hiring managers and candidates. 
                            by subscribing to our newsletter, you'll be among the first to receive the upcoming PDFs, ensuring you gain a competitive advantage in your hiring process or career journey. 
                            stay informed about the latest industry insights, expert advice, and exclusive resources tailored to your needs. don't miss out on these upcoming releases! simply enter 
                            your email address below to subscribe and receive priority access to our future PDFs. stay ahead of the curve and unlock the next level of success in your recruitment 
                            endeavors or professional growth.</p>
                        <form onSubmit={handleSubscribe}>
                            <label htmlFor='email'>
                                Your contact email address
                            </label>
                            <input 
                                type='email'
                                id='email'
                                name='email'
                                autoComplete='email'
                                required
                                value={formData.email}
                                onChange={handleChange}
                            /> 
                            <div className={styles.dataPolicySubs}>
                        <input 
                            type='checkbox'
                            id='data-policy' 
                            name='dataProtectionConsent'
                            required
                            checked={formData.dataProtectionConsent}
                            onChange={handleChange}
                        />
                        <label htmlFor="data-policy">
                            <DataProtection />
                        </label>
                    </div>
                    <ReCaptcha onChange={handleRecaptchaChange} />
                    <button type='submit'>SUBSCRIBE</button>
                </form>
                <p>{message}</p>
                    </div>
                </div>
            </div> 
        </section>
    );
};

export default PDFSection;