import React, { useState } from 'react';
import DataProtection from './DataProtection';
import styles from '../styles/DownloadServices.module.css';
import { collectEmail, downloadPdf } from '../utils/api';

const DownloadServices = ({ onClose }) => {
  const [formData, setFormData] = useState({
    email: '',
    dataProtectionConsent: false,
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleDownload = async () => {
    try {
      await downloadPdf('services');
      setResponseMessage('PDF downloaded successfully');
    } catch (error) {
      console.error('Download error:', error);
      setResponseMessage(`Failed to download PDF: ${error.message}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!formData.email || !formData.dataProtectionConsent) {
        setResponseMessage('Please fill all required fields');
        return;
      }
    
      await collectEmail(formData.email);  
      setResponseMessage('Email collected successfully. Starting download...');
      await handleDownload();
    } catch (error) {
      console.error('Submission error:', error);
      setResponseMessage(`Failed to process request: ${error.message}`);
    }
    
  };

  return (
    <div className={styles.popup}>
      <div className={styles.popupContent}>
        <span className={styles.close} onClick={onClose}>&times;</span>
        <h3>Please Enter Your Email</h3>
        <form onSubmit={handleSubmit}>
          <input
            type='email'
            name='email'
            placeholder='Enter your email'
            autoComplete='email'
            value={formData.email}
            onChange={handleChange}
            required
          />
          <div className={styles.dataPolicy}>
            <input 
              type='checkbox'
              id='data-policy' 
              name='dataProtectionConsent'
              checked={formData.dataProtectionConsent}
              onChange={handleChange}
              required 
            />
            <DataProtection />
          </div>
          <button type="submit">GET SERVICES</button>
        </form>
        {responseMessage && <p className={styles.message}>{responseMessage}</p>}
      </div>
    </div>
  );
};

export default DownloadServices;