import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from '../styles/Data.module.css';

const Data = () => {
  return (
    <div className={styles.dataContainer}>
      <section id='data' className={styles.dataSection}>


        <h1 className={styles.title}>Consent with the processing <br />of personal data</h1>

        <div className={styles.content}>
          <p>This document outlines the method of processing personal data based on the consent provided by data subjects for the purpose of receiving newsletters and engaging in other marketing activities conducted by the data administrator.</p>
          
          <Section title="1. Identity and Contact Information of the Data Administrator">
            <p>Your data administrator is Recberry s.r.o.| IC: 04972236 | DIC: CZ04972236 | Tupolevova 741, Prague 9 Letnany | Incorporated in the Commercial Register kept by the City: Court in Prague, Section C, under file No.256441 (hereinafter referred to as the "Administrator")</p>
            <p>Administrator's Mailing Address: Recberry s.r.o.| IC: 04972236 | DIC: CZ04972236 | Jana Zajice 2016, 258 01 Vlasim, Czechia <br />
              Email Address: barbora@recberry.com <br />
              Telephone: +420 702 080 993
            </p>
            <p>The Administrator has not appointed a person authorized to process personal data.</p>
          </Section>

          <Section title="2. Legal Basis for Data Processing">
            <p>The legal basis for processing your personal data is your consent given to the Administrator in accordance with Article 6 (1) (a) of the European Regulation of the Parliament and Council (EC) No. 2016/679 on the protection of individuals with regard to the processing of personal data and on the free movement of data, and repealing Directive No. 95/46 / EC (General Data Protection Regulation).</p>
          </Section>

          <Section title="3. Purpose of Personal Data Processing">
            <p>The purpose of processing your personal data is to contact you, send you newsletters and perform other marketing activities on behalf of the Administrator. There is no automatic individual decision-making process carried out by the Administrator under Article 22 of the Regulation.</p>
          </Section>

          <Section title="4. Personal Data Storage Period">
            <p>Your personal data will be stored for a duration of 5 years.</p>
          </Section>

          <Section title="5. Other Recipients of Your Personal Data">
            <p>Other recipients of your personal data are individuals who provide marketing services for the Administrator. The Administrator has no intention to transfer your personal data to a third country (non-EU country) or to an international organization.</p>
          </Section>

          <Section title="6. Data Subject Rights">
            <p>In accordance with the Regulation, you have the right to request access to your personal data, rectify or erase your personal data, or restrict their processing. You also have the right to object to the processing of your personal data and the right to data portability.</p>
            <p>You can withdraw your consent for the processing of your personal data given to the Administrator at any time. However, withdrawal of consent does not affect the lawful processing of your personal data prior to the withdrawal.</p>
            <p>If you believe that the processing of your personal data violates the Regulation, you have the right to file a complaint with the Supervisory Authority, in addition to other rights.</p>
            <p>Providing your personal data is not obligatory. It is neither a legal nor a contractual requirement, nor is it required to enter into a contract.</p>
            <p>You have the right to object to the processing of your personal data for direct marketing purposes, including profiling related to such direct marketing. If you object to the processing of your personal data for direct marketing purposes, your personal data will no longer be processed for this purpose.</p>
          </Section>

          <Section title="7. Compliance with GDPR (General Data Protection Regulation)">
            <p>This data processing policy is in full compliance with the General Data Protection Regulation (GDPR) – European Regulation (EU) 2016/679, which ensures the protection of individuals with regard to the processing of personal data and the free movement of data. The Administrator is committed to upholding the principles and requirements outlined in the GDPR to safeguard your personal data and respect your rights as a data subject. If you have any concerns or inquiries regarding the processing of your personal data, please do not hesitate to contact us.</p>
            <p>As a data subject under the General Data Protection Regulation (GDPR), you have the following rights concerning the processing of your personal data:</p>
            <ul>
              <li><strong>Right to Access:</strong> You have the right to request access to your personal data held by the Administrator. This includes information about the purposes of the processing, the categories of personal data concerned, and the recipients of the data.</li>
              <li><strong>Right to Rectification:</strong> You can request the correction or rectification of inaccurate or incomplete personal data.</li>
              <li><strong>Right to Erasure (Right to be Forgotten):</strong> You have the right to request the erasure of your personal data under specific circumstances, such as when the data is no longer necessary for the purposes it was collected or if you withdraw your consent.</li>
              <li><strong>Right to Restriction of Processing:</strong> You can request the restriction of processing your personal data in certain situations, such as when the accuracy of the data is contested, or the processing is unlawful.</li>
              <li><strong>Right to Object:</strong> You have the right to object to the processing of your personal data, including profiling, for direct marketing purposes. The Administrator will cease processing your data for this purpose upon your objection.</li>
              <li><strong>Right to Data Portability:</strong> You can request to receive your personal data, which you have provided to the Administrator, in a structured, commonly used, and machine-readable format, and have the right to transmit this data to another data controller.</li>
              <li><strong>Right to Withdraw Consent:</strong> If the processing of your personal data is based on your consent, you have the right to withdraw your consent at any time. However, this withdrawal will not affect the lawfulness of the processing carried out before the withdrawal.</li>
              <li><strong>Right to Lodge a Complaint:</strong> If you believe that the processing of your personal data violates the GDPR, you have the right to lodge a complaint with the relevant Supervisory Authority.</li>
            </ul>
          </Section>

          <p className={styles.signature}>Recberry s.r.o.</p>
        </div>
      </section>
    </div>
  );
};

const Section = ({ title, children }) => (
  <div className={styles.section}>
    <h4>{title}</h4>
    {children}
  </div>
);

export default Data;