import React, { useEffect } from 'react';

const ReCaptcha = ({ onChange }) => {
  useEffect(() => {
    window.onRecaptchaSuccess = () => {
      console.log('reCAPTCHA callback triggered');
      if (onChange) {
        onChange(true);
      }
    };

    // Only render if it hasn't been rendered yet
    const recaptchaDiv = document.getElementById('recaptcha');
    if (recaptchaDiv && !recaptchaDiv.hasChildNodes()) {
      if (window.grecaptcha && window.grecaptcha.render) {
        try {
          window.grecaptcha.render('recaptcha', {
            sitekey: '6LeaeGcqAAAAANQPjUx9Up-5_L8WRX-V7xVjoaPx',
            callback: 'onRecaptchaSuccess'
          });
        } catch (error) {
          // Ignore "already rendered" error
          if (!error.message.includes('already been rendered')) {
            console.error('Error rendering reCAPTCHA:', error);
          }
        }
      }
    }

    return () => {
      delete window.onRecaptchaSuccess;
    };
  }, [onChange]);

  return (
    <div 
      id="recaptcha"
      className="g-recaptcha" 
      data-sitekey="6LeaeGcqAAAAANQPjUx9Up-5_L8WRX-V7xVjoaPx"
      data-callback="onRecaptchaSuccess"
    ></div>
  );
};

export default ReCaptcha;