import React from 'react';
import styles from '../../styles/TalentHMSection.module.css';
import FormTalent from '../FormTalent';

const TalentSection = () => {
    return (
        <section id='talent' className={styles.talent}>
            <div className={styles.subSection}>
                <div className={styles.subItem}>
                    <h2>the talent</h2>
                    <div className={styles.subSection}>
                        <div className={styles.blackBoxLeft}>
                            <div className={styles.subSectionHalf}>
                                <div className={styles.subItems}>
                                    <h4>Propel your career growth</h4>
                                    <p className={styles.styled}>our expert advice provides you with valuable insights, strategies, and resources to accelerate
                                        your career growth. from personal branding and networking tips to interview techniques and negotiation strategies, 
                                        we equip you with the tools you need to stand out and advance in your profession</p>
                                </div>
                                <div className={styles.subItems}>
                                    <h4>Navigate the job market with confidence</h4>
                                    <p className={styles.styled}>stay ahead of the competition and confidently navigate the job market with our industry-specific
                                        insights. we provide you with up-to-date trends, market analysis, and tailored job search strategies, empowering you 
                                        to make informed decisions and find the right opportunities for your career.</p>
                                </div>
                            </div>
                            <div className={styles.subSectionHalf}>
                                <div className={styles.subItems}>
                                    <h4>Access exclusive job opportunities</h4>
                                    <p className={styles.styled}>gain access to a wide range of exclusive job opportunities through our network of reputable 
                                        companies. We partner with top organizations across industries, giving you an inside track to exciting roles that 
                                        align with your skills, experience, and aspirations.</p>
                                </div>
                                <div className={styles.subItems}>
                                    <h4>Enhance your professional development</h4>
                                    <p className={styles.styled}>expand your knowledge and skills through our curated resources. in our downloadable guides and 
                                        industry reports, we offer you valuable resources that foster continuous learning and professional development.</p>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
                <div className={styles.subItem}>
                    <h5>Seize these exclusive benefits today! Enter your email address below to gain access to our downloadable PDFs and unlock the pathways 
                        to career success that you truly deserve.</h5>
                    <FormTalent />
                </div>
            </div>
        </section>
    );
};

export default TalentSection;